/* Bootstrap overrides
-------------------------------------------------- */
@font-face {
	font-family: BubbleGum;
	src: url("../Bubblegum.ttf");
}

@font-face {
	font-family: MorningBreeze;
	src: url("../MorningBreeze.otf");
}

@font-face {
	font-family: MorningBreezeBold;
	src: url("../MorningBreezeBold.otf");
}

$cucumber-color-1: #24481a;
$cucumber-color-2: #e3fdce;
$cucumber-color-4: #c0ebf1;

$body: $cucumber-color-1;
$headings-font-weight: 300 !important;
$primary: $cucumber-color-1;
$secondary: $cucumber-color-2;
$light: $cucumber-color-2;
$card-border-radius: 0.75rem;
$card-inner-border-radius: subtract($card-border-radius, 1px);

$headings-font-weight: 300 !important;
$a: #1392ff;

/** MULTIVERSX DAPP CORE COMPONENTS
-------------------------------------------------- */

.dapp-core-component__transactionsToastList-styles__toastWrapper,
.dapp-core-component__dappModalStyles__dappModalBody,
.dapp-core-component__dappModalStyles__dappModalContent {
	background-color: white !important;
	border-color: rgba(255, 255, 255, 0.201267) !important;
}

.dapp-core-component__transactionsToastList-styles__toastWrapper {
	border-style: solid;
	border-width: 1px;
}

.dapp-core-component__transactionToast-styles__close,
.dapp-core-component__addressTableStyles__ledger-address-table-navigation-button,
.dapp-core-component__dappModalStyles__dappModalCloseButton {
	color: $body !important;
	background-color: white !important;
}

.dapp-core-component__main__btn-primary {
	background-color: $primary !important;
	border-color: $primary !important;
	color: $secondary !important;
}

.dapp-core-component__addressTableStyles__ledger-address-table-header,
.dapp-core-component__confirmAddressStyles__ledger-confirm-address-data,
.dapp-core-component__main__textarea {
	background-color: $secondary !important;
	color: $body !important;
}

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import "~bootstrap/scss/bootstrap.scss";

@import "~bootstrap/scss/reboot";

/*
CUCUMBERX TEMPLATE
-------------------------------------------------- */

body {
	font-family: "MorningBreezeBold", "IBM Plex Mono", monospace;
	color: $body;
}

.font-MorningBreeze {
	font-family: "MorningBreeze", "IBM Plex Mono", monospace;
}

.font-MorningBreezeBold {
	font-family: "MorningBreezeBold", "IBM Plex Mono", monospace;
}

.bg-light {
	background-color: $cucumber-color-2 !important;
}

nav {
	background-color: $cucumber-color-2 !important;
}

.card {
	background-color: white !important;
}

.custom-classname.btn-primary {
	background-color: green;
}

.btn-primary {
	color: $secondary !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.card-dashboard {
	max-width: 350px;
	padding: 16px;

	img {
		max-width: 75%;
		max-height: 200px;

		margin: 0 auto;
	}
}

.flex-grow-1-md {
	@include media-breakpoint-down(md) {
		flex-grow: 1 !important;
	}
}

/* Personalized
-------------------------------------------------- */

.nft-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	gap: 20px;
	place-content: flex-start space-evenly;

	.card-nft {
		max-width: 225px;
		position: relative;
		background-color: white !important;
	}
}

.nft-checkbox-container {
	position: absolute;
	top: 10px;
	right: 25px;
	z-index: 10;

	.nft-checkbox {
		width: 30px;
		height: 30px;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.75);
	}
}

/* Base
-------------------------------------------------- */
html {
	min-height: 100%;
	font-size: 14px;
}

.wrapper {
	min-height: 100vh;
}

.opacity-6 {
	opacity: 0.6;
}

.dapp-icon {
	padding: 5px;
	border-radius: 50%;
	background-color: $light;
	width: 65px;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	&.icon-medium {
		width: 80px;
		height: 80px;
	}
}

/* Navbar
-------------------------------------------------- */
.cucumberx-logo {
	width: 150px;
}

.dapp-name {
	line-height: 1;
	border-left: 1px solid $gray-400;
	padding-left: 3px;
	margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $white;
	margin: 0 7px;

	button {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background-color: $white;

		&:hover {
			opacity: 0.9;
		}
	}
}

/* Sign Message
-------------------------------------------------- */
.sign-message {
	& h1 {
		font-size: 24px;
	}

	& textarea {
		min-height: 100px;
	}

	& button {
		align-self: center;
	}
}

.textarea-with-copy {
	position: relative;

	& a {
		position: absolute;
		right: 4px;
		bottom: 4px;
	}

	@include media-breakpoint-up(md) {
		min-width: 650px;
	}
}

.transactions {
	.transaction-icon {
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}

	&.table {
		.transaction-hash .trim {
			@include media-breakpoint-down(xl) {
				max-width: none;
			}
			@include media-breakpoint-down(lg) {
				max-width: 26rem;
			}
			@include media-breakpoint-down(md) {
				max-width: 12rem;
			}
			@include media-breakpoint-down(sm) {
				max-width: 8rem;
			}
		}

		td,
		tr {
			padding: 0.65rem;
		}

		td {
			white-space: nowrap;
			vertical-align: middle;
		}

		tr:last-of-type {
			td {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
}

/* Actions
-------------------------------------------------- */

.not-allwed {
	cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 64px;
		height: 64px;
	}
	.lds-ellipsis div {
		position: absolute;
		top: 27px;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.1);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	.bg-blue .lds-ellipsis div {
		background: rgba(255, 255, 255, 0.4);
	}
	.lds-ellipsis div:nth-child(1) {
		left: 6px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 6px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 26px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 45px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(19px, 0);
		}
	}
}

/* Footer
-------------------------------------------------- */
footer {
	div {
		margin-top: 0.5rem;
		font-size: 0.75rem;
		display: inline-block;
		font-weight: 400;
		line-height: 1.155rem;

		a {
			&:hover {
				color: $black;
			}

			#heart {
				height: 16px;
				width: 16px;

				path {
					fill: #c00;
				}
			}
		}
	}
}
